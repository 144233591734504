import axios from 'axios';

/**
 * @description 내 카드 정보
 * @returns Promise
 */
const getMyCardInfo = async userId => {
  return await axios.post('/api/product/getMyCardInfo', { userId });
};

export { getMyCardInfo };
